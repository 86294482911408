import MUTextField from '@material-ui/core/TextField'

function TextField({ field, form: { touched, errors }, label, helperText, ...props }) {
  return (
    <MUTextField
      label={label}
      placeholder={label}
      error={touched[field.name] && errors[field.name]}
      helperText={(touched[field.name] && errors[field.name]) || helperText}
      {...field}
      {...props}
    />
  )
}

export default TextField
