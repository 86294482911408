//import './App.css'
import Tabs from '../Tabs'

function App() {
  return (
    <div className="App">
      {/*<header className="App-header">
                    <h1 className="App-title">Добро пожаловать в VK приложение Объявления</h1>
                </header>*/}
      <div className="App-intro">
        <Tabs />
      </div>
    </div>
  )
}

export default App
