import { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Formik, Form, Field } from 'formik'

import wallStyles from './styles'

import ResultDialog from '../common/dialogs/ResultDialog'
import PromptDialog from '../common/dialogs/PromptDialog'
import TextField from '../common/Fields/TextField'
import AdvertsMeta from '../AdvertsMeta'

const useStyles = makeStyles(wallStyles)

function WallPostPage(props) {
  const classes = useStyles()
  const initialValues = { title: '', descr: '', price: '', tags: '' }
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [resultDialogOpen, setResultDialogOpen] = useState(false)
  const wall = { message: 'ok' }

  return (
    <>
      <div className={classes.container}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            setConfirmDialogOpen(true)
          }}
        >
          <Form className={classes.mainForm}>
            <div>
              <Field
                required
                id="outlined-helperTitle"
                label="Название"
                className={classes.textField}
                // helperText="От 3 до 45 символов"
                margin="normal"
                variant="outlined"
                name="title"
                component={TextField}
              />
            </div>
            <div>
              <Field
                required
                id="outlined-multiline-static"
                label="Описание"
                multiline
                rows="4"
                className={classes.textField}
                helperText="рекомендуем до 1145 символов"
                margin="normal"
                variant="outlined"
                name="descr"
                component={TextField}
              />
            </div>
            <div>
              <Field
                id="outlined-helperPrise"
                label="Цена"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="price"
                component={TextField}
              />
            </div>

            <AdvertsMeta />

            <div>
              <Field
                id="outlined-helperTags"
                label="Хештеги"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="tags"
                component={TextField}
              />
            </div>
            <div className={classes.wrapper}>
              <br />
              <Typography variant="caption">* разделы обязательные к заполнению</Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                /*disabled={selectedFiles && !uploaded}*/
              >
                Отправить
              </Button>
            </div>
          </Form>
        </Formik>
        <ResultDialog
          dialogOpen={resultDialogOpen}
          onClose={() => setResultDialogOpen(false)}
          wall={wall}
        />
        <PromptDialog
          dialogOpen={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={() => {} /*handleSubmit(this.onSubmit)*/}
        />
      </div>
    </>
  )
}

export default WallPostPage
