import { combineReducers } from 'redux'
import wallReducer from './wall'
import deparmentsReducer from './departments'

const rootReducer = combineReducers({
  wall: wallReducer,
  departments: deparmentsReducer,
})

export default rootReducer
