import { useState, useEffect } from 'react'
import { Field } from 'formik'
import { makeStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import selectFieldStyles from './styles'

const useStyles = makeStyles(selectFieldStyles)

function SelectFields({ title, departs, departments, type, layer = 0, ...props }) {
  const classes = useStyles()
  const [depart, setDepart] = useState('')

  useEffect(() => {
    setDepart('')
  }, [type])

  const renderSelectField = ({ field, label, form: { touched, errors }, children, ...custom }) => (
    <TextField
      select
      required
      label={label}
      placeholder={label}
      onChange={custom.onChange}
      error={touched[field.name] && errors[field.name]}
      helperText={touched[field.name] && errors[field.name]}
      {...field}
      {...custom}
    >
      {children}
    </TextField>
  )

  const renderTopLayer = () => (
    <>
      <div style={{ width: '100%' }}>
        <Field
          require
          id={`outlined-helperDepart${layer}`}
          variant="outlined"
          label={title || 'Раздел'}
          name={`depart${layer}`}
          className={classes.formControl}
          component={renderSelectField}
          value={depart}
          onChange={(e) => setDepart(e.target.value)}
          key={`depart${layer}-${type}`}
        >
          {departs.map((depart) => (
            <MenuItem value={depart.id} key={depart.id}>
              {depart.print}
            </MenuItem>
          ))}
        </Field>
      </div>

      {renderChildren(departs, layer + 1, type)}
    </>
  )

  const renderChildren = (departs, layer, type) => {
    if (depart === undefined) return null
    const department = departs.find((item) => item.id === depart)
    if (!department || !department.children) return null

    if (department.multiple) {
      renderMultiple(departs, layer)
    }

    return (
      <SelectFields
        departs={department.children}
        title={department.print}
        layer={layer}
        type={type}
      />
    )
  }

  const renderMultiple = (departs, layer) => {
    return departs.children.map((depart, index) => {
      return <SelectFields departs={depart.children} title={depart.print} layer={layer + index} />
    })
  }

  if (!layer && !departments) return null
  console.log(departments)

  if (!layer) {
    if (Array.isArray(departments.value)) {
      departs = departs.filter((depart) => departments.value.includes(depart.id))
    } else {
      while (departments.next) {
        departs = departs.find((depart) => depart.id === departments.value).children
        departments = departments.next
        layer++
      }
      const d = departs.find((depart) => depart.id === departments.value)
      if (d.multiple) {
        return renderMultiple(d, layer)
      } else if (layer) {
        return <SelectFields departs={d.children} title={d.print} layer={layer} type={type} />
      }
    }
  }

  return renderTopLayer()
}

export default SelectFields
