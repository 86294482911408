import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import SelectFields from '../SelectFields'

import config from '../../db.json'
import { Field } from 'formik'

import selectFieldStyles from './styles'

const useStyles = makeStyles(selectFieldStyles)
const types = config.types

function AdvertsMeta(props) {
  const classes = useStyles()
  const [type, setType] = useState('')
  return (
    <>
      <div style={{ width: '100%' }}>
        <Field
          select
          require
          id="type"
          variant="outlined"
          label="Тип объявления"
          name="type"
          className={classes.formControl}
          component={TextField}
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          {types.map((type) => (
            <MenuItem value={type.id} key={type.id}>
              {type.print}
            </MenuItem>
          ))}
        </Field>
      </div>

      <SelectFields
        departs={config.departments}
        departments={types.find((t) => t.id === type)?.departments}
        type={type}
      />
    </>
  )
}

export default AdvertsMeta
