import { makeStyles } from '@material-ui/core/styles'
import TabsMU from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import WallPostPage from '../WallPostPage'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.primary,
  },
}))

function Tabs() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <TabsMU value={0} indicatorColor="primary">
          <Tab label="Новое объявление" />
        </TabsMU>
      </AppBar>
      <Typography component="div" style={{ padding: 8 * 3 }}>
        <WallPostPage />
      </Typography>
    </div>
  )
}

export default Tabs
